
import { deliveryWay as deliveryWayEnum } from "@/enum/enum.json"

const shipmentNoRegs = {
    //DHL 规则
    '10': [
        //https://mydhl.express.dhl/es/en/forms/tracking-number-not-10-digits.html
        //https://www.ship24.com/zh/couriers/dhl-tracking
        //DHL 包裹服务： 有一个 10 位数字的跟踪号，以 3S、JVGL 或 JJD 开头。
        //DHL快递服务： 跟踪号将有一个以 000、JJD01、JJD00、JVGL 或类似变体开头的 10 位数字。
        //Shipments which starts by: 3S, JVGL or VGL, e.g. JVGL9999999999
        "^(3S|JVGL|(JJD(00|01)?)|VGL|000)?[0-9]{10}$",
        //ES 发货 - 12 位数字，例如 999999999999
        "^[0-9]{12}$",
        //DHL电子商务服务： 跟踪号的长度为 10 到 39 个字符。 它通常以 GM、LX 和 RX 开头。 或者，跟踪号最多可以以五位数字开头。
        //If your tracking number starts with GM, LX, RX, AU or TH, please track your shipment at https://webtrack.dhlglobalmail.com/.
        "^(?=.{10,39}$)(GM|LX|RX|AU|TH)?[0-9]+$",
        //Shipment from Europe - starts with 2 letters e.g. CH400397205DE
        "^[A-Z]{2}[0-9]{9}[A-Z]{2}$",
        // Shipments which stars by 2 letters + 7 numbers + 0, e.g. DE99999990
        "^[A-Z]{2}[0-9]{7}0$",
        //DHL全球转运服务： 跟踪号可能有多种不同的跟踪代码变体，包括仅 7 位数字、1 位数字后跟 2 个字母和 4-6 个数字或以 3 或 4 个字母开头、3 位承运人代码、后跟破折号 ，然后是一个 8 位数字。 以上所有内容可能会有所变化。
        //全球转运相关暂不支持
    ],
    //UPS 规则
    '20': [
        //在美国发布的 UPS 包裹的追踪号码往往采用 18 位字母和数字集合的格式。 有时，UPS 追踪编号以 1Z 开头并以数字结尾。 下面是一个例子：1Z 999 AA1 01 9371 6784
        //暂时限定为1Z开头的跟踪号
        '^1Z[0-9A-Z]{16}$',
    ],
    //USPS美国邮政单号规则：( # 代表字母, ~ 代表数字, * 代表字母或数字 。） 
    '30': [
        //( R#/V#/A#/E#/L#/D#/G#/T#/M ~~~ ~~~ ~~~ US)
        "^(([RVAELDGT][A-Z])|(M))[0-9]{9}US$",
        //( ~~~ ~~~ ~~~ ~ )
        "^[0-9]{10}$",
        //( ~~~ ~~~ ~~~ ~~~ ~~~ ~~~ ~~ )
        "^[0-9]{20}$",
        //( ~~~ ~~~ ~~~ ~~~ ~~~ ~~~ ~~~ ~ )
        "^[0-9]{22}$",
        //或者30位数字
        "^[0-9]{30}$",
    ],
    //FEDEX 规则
    '40': [
        //12位数字
        '^[0-9]{12}$',
        //20位数字
        '^[0-9]{20}$',
    ],
    //Estafeta 规则
    '50': [
        //Estafeta跟踪号由10位数字组成，位于收据的顶部，靠近工作表右侧的垂直条形码。对于Estafeta跟踪墨西哥，您可以同时使用跟踪号和22位数字的跟踪号。
        "^[0-9A-Z]{10}([0-9A-Z]{12})?$",
    ],
    //Paquetexpress 规则
    '60': [
        //Paquetexpress跟踪号很容易识别，因为它由12位数字组成，是数字和字母字符的组合。例如“ MZR98HZ84420”。
        //Paquetexpress  通常是8-15位字符或数字，有时候使用Reference(西语Referencia)会多出6位  rep : 202210101701
        "^[0-9A-Z]{8,21}$",
    ],
    //amzl 规则
    '70': [
        //暂未找到对应规则文档
        "^TB[AM][0-9]{12}$"
    ],
    //Mexico Post 规则
    '80': [
        //墨西哥邮政单号规则：( # 代表字母, ~ 代表数字, * 代表字母或数字,/表示或者 。）  
        //( R#/V#/A#/C#/E#/L#/M# ~~~ ~~~ ~~~ MX)
        "^[RVACELM][A-Z][0-9]{9}MX$",
    ],
    //Grupo ampm 规则
    '90': [
        //8 - 15位长，无序，可以包含字母和数字
        "^[0-9A-Z]{8,15}$",
    ],
    //RedPack 规则
    '120': [
        //8 - 15位长，无序，可以包含字母和数字
        "^[0-9]{8,9}$",
    ],
    // IMILE
    '130': [
        // 12-14位数字
        "^[0-9]{12,14}$",
    ],
    //(退货包裹)备用识别信息 规则
    'standby_identification': [
        "^[0-9a-zA-Z-]{1,}$",
    ],
    //默认规则
    '_default': [
        "^[0-9A-Za-z-]{5,40}$",
    ]
};


export const isShipmentNoValid = (deliveryWayValue, shipmentNo) => {
    var regPatternArr = shipmentNoRegs["" + deliveryWayValue];
    if (deliveryWayValue && !regPatternArr) {
        //兼容直接传枚举字符串,如 dhl, mexicopost 等等
        var regKey = deliveryWayEnum[("" + deliveryWayValue).toLowerCase()];
        regPatternArr = shipmentNoRegs["" + regKey];
    }
    if (!regPatternArr) {
        regPatternArr = shipmentNoRegs["_default"];
    }
    if (regPatternArr) {
        let reg;
        for (var i = 0; i < regPatternArr.length; i++) {
            reg = new RegExp(regPatternArr[i]);
            if (reg.test(shipmentNo)) {
                return true;
            }
        }
    }

    return false;
}